
function arrayTool( arr, opt ) {
  if( !arr ) return opt.asObject ? {total: 0, data: []} : [];
  const filter = opt.filter, from = opt.from || 0, to = opt.to || 99999, asObject = opt.asObject,
    srcFrom = opt.srcFrom || 0, srcTo = opt.srcTo || arr.length,
    ordered = opt.ordered, map = opt.map, sort = opt.sort, keepFalse = opt.keepFalse,
    prepend = opt.prepend;
    let res = [];
    let total = 0;
    for( let i = srcFrom; i < srcTo; i++ ) { //Filtering
      if( !filter || filter( arr[i], i)) {
        res.push( arr[i] );
        total++;
      }
    }
    if( sort ) {
      res = res.sort( sort );  // Sorting
    } if( ordered ) {
      res = res.sort();
    }
    if( !map && !from && !to ) { //Optimization
      if( asObject ) return { total, data: res};
      return res;
    }
    const last = res.length < to ? res.length : to;

    const ret = [];
    for( let i = from; i < last; i ++ ) { //Mapping and pagging
      if( map ) {
        const el = map( res[i], i);
        if( keepFalse || el ) ret.push( el );
      } else {
        if( keepFalse || res[i] ) ret.push( res[i] );
      }
    }
    if( prepend && prepend.length ) prepend.forEach( el => { el && ret.unshift( el ) } );
    if( asObject ) return { total, data: ret};
    return ret;
}

export default arrayTool;
