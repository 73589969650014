import * as ac from './action_const';

import * as cacheTime from './cacheTime';



export function changeProp( table, field, property, value) {
  return {
    type: ac.CHANGE_PROP,
    table: table,
    field: field,
    property: property,
    value: value
  };
}

export function changeLink( table, field, fieldName, linkedTable, linkedField, linkType) {
  return {
    type: ac.CHANGE_LINK,
    table: table,
    field: field,
    property: 'link',
    value: {
      table: linkedTable,
      field: linkedField,
      type: linkType || ''
    }
  }
}

export function loaded( table, data ) {
  return {
    type: ac.TABLE_DEF_LOADED,
    table: table,
    data: data
  }
}



export function changeTableAutocomplete( table ) {
  return {
    type: ac.UI_CHANGE_AC,
    table
  }
}

// UI

export const uiToggle = (key) => ({ type: ac.UI_TOGGLE, key })
export const goHistory = () => ({ type: ac.UI_GO_HISTORY})
export const goHome = table => ({ type: ac.UI_GO_HOME, table })
export const goPage = (page, title) => ({ type: ac.UI_GO_PAGE, page, title })
export const goFieldsSearch = () => ({ type: ac.UI_GO_FIELDS_SEARCH })
export const goFieldsView = table => ({ type: ac.UI_GO_FIELDS_VIEW, table })
export const goTable = table => ({ type: ac.UI_GO_TABLE, table })
export const goField = (table, field) => ({ type: ac.UI_GO_FIELD, table, field })
export const goAllField = ( field) => ({ type: ac.UI_GO_ALL_FIELD,  field })
export const uiToggleMenu = () => ({ type: ac.UI_TOGGLE_MENU })
export const setViewMode = mode => ({ type: ac.UI_SET_VIEW_MODE, mode })
export const uiChangePage = (pager, page) => ({
 type: ac.UI_CHANGE_PAGE, pager, page
})
export const uiChangePerPage = (pager, perPage) => ({
  type: ac.UI_CHANGE_PER_PAGE, pager, perPage
})

export const updateFieldDef = ( table, field, values ) => ({
   type: ac.UPDATE_FIELD_DEF, method: 'event', uri: 'send', table, field, arg: values
});
export const updateTableDef = ( table, values ) => ({
  type: ac.UPDATE_TABLE_DEF, method: 'event', uri: 'send', table,  arg: values
});


//************* SERVER

export function logout() {
  return {
    type: ac.LOGOUT
  }
}

export const login = (user, password) => ({
  type: ac.LOGIN,
  method: 'POST',
  uri: 'login',
  arg: { login: user, password: password }
});


export const verifySession = () => {
  return {
    type: ac.VERIFY_SESSSION,
    uri: 'me',
    expireIn: cacheTime.HOUR
  }
};

export const loadTables = () => {
  return {
    type: ac.LOAD_TABLES,
    uri: 'tables',
    expireIn: cacheTime.DAY
  }
};

export const loadAll = () => {
  return {
    type: ac.LOAD_ALL,
    uri: 'tables_all',
    expireIn: cacheTime.HOUR
  }
};


export const loadStats = () => {
  return {
    type: ac.LOAD_STATS,
    uri: 'stats',
    expireIn: cacheTime.DAY
  }
};


export const loadTable = ( table ) => {
  return {
    type: ac.LOAD_TABLE,
    uri: 'table/' + table,
    expireIn: cacheTime.DAY
  }
};

export const refreshDb = () => {
  return {
    type: ac.REFRESH_DB,
    uri: 'refresh'
  }
};


export const updateTableFromDb = ( table, tdef ) => ({
  type: ac.BE_UPDATE_TABLE,
  table,
  payload: tdef
})

export const udateStats = ( table, tdef ) => ({type: ac.BE_UPDATE_STATS })
export const beLoadDb = ( data ) => ({type: ac.BE_LOAD_DB, payload: data })
