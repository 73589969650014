import * as ac from "./action_const";

const initialState = {
    page: 'welcome',
    viewMode: 1,
    navi: ['home', 'db'],
    db: 'default',
    toggle: {},
    pagers: {
        fields: {
            page: 0,
            perPage: 25
        },
        tables: {
            page: 0,
            perPage: 25
        },
        fieldslist: {
            page: 0,
            perPage: 10
        }
    },
    history: [],
    tabsOpen: {},
    table: '',
    field: '',
    title: 'dbZnay',
    menuOpen: false,

    articles: [],
    selectedTable: '',
    autocompleteTable: ''
};
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case ac.UI_TOGGLE:
            return {...state, toggle: {...state.toggle, [action.key]: !state.toggle[action.key]}}
        case ac.UI_SET_VIEW_MODE:
            return {...state, viewMode: action.mode};
        case ac.UI_TOGGLE_MENU:
            return {...state, menuOpen: !state.menuOpen};
        case ac.UI_GO_PAGE:
            return {
                ...state,
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }],
                menuOpen: false, page: action.page, title: action.title || 'dbZnay'
            }
        case ac.UI_GO_HOME:
            return {
                ...state, menuOpen: false, page: 'index', title: 'dbZnay',
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }],
            }
        case ac.UI_GO_HISTORY:
            return {
                ...state, menuOpen: false, page: 'history', title: 'History',
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }],
            }
        case ac.UI_GO_FIELDS_SEARCH:
            return {
                ...state, menuOpen: false, page: 'fields_search', title: 'fields',
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }]
            }
        case ac.UI_GO_FIELDS_VIEW:
            return {
                ...state, menuOpen: false, page: 'fields_view', table: action.table, title: action.table,
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }]
            }
        case ac.UI_GO_TABLE:
            return {
                ...state, menuOpen: false, page: 'table', table: action.table, title: 'Table ' + action.table,
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }],
            }
        case ac.UI_GO_FIELD:
            return {
                ...state,
                menuOpen: false,
                page: 'field',
                table: action.table,
                field: action.field,
                title: action.table + ' - ' + action.field,
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }]
            }
        case ac.UI_GO_ALL_FIELD:
            return {
                ...state, menuOpen: false, page: 'allfield', field: action.field, title: 'Fields ' + action.field,
                history: [...state.history, {
                    page: state.page,
                    title: state.title,
                    table: state.table,
                    field: state.field
                }]
            }

        case ac.UI_CHANGE_PAGE:
            return {
                ...state,
                pagers: {...state.pagers, [action.pager]: {...state.pagers[action.pager], page: action.page}}
            };
        case ac.UI_CHANGE_PER_PAGE:
            return {
                ...state,
                pagers: {...state.pagers, [action.pager]: {...state.pagers[action.pager], perPage: action.perPage}}
            };


        // OLD STUFF
        case ac.UI_CHANGE_TABLE:
            return {...state, selectedTable: action.table, autocompleteTable: action.table};
        case ac.UI_CHANGE_AC:
            return {...state, autocompleteTable: action.table};
        default:
            return state;
    }
};
export default rootReducer;
