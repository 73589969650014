import React from 'react';
import { connect } from "react-redux";
import * as actions from '../actions';
import loader  from '../loader';
import Button from '@material-ui/core/Button';

import Plate from '../ui/Plate';

const Welcome = (st) => {
  return (
    <div>
      <Plate>
        <div className="marg10">Click on your favorite edit mode
        </div>
        <div>
          <Button onClick={()=>{ st.dispatch( actions.goHome())}} className="marg10" color="primary"  variant="contained" >Edit tables</Button>
          <Button onClick={()=>{ st.dispatch( actions.goFieldsSearch())}} className="marg10" variant="contained" >Edit fields</Button>
          <Button onClick={()=>{ st.dispatch( actions.goHome())}} className="marg10" variant="contained" >Reload</Button>
        </div>
      </Plate>
      <Plate>
        <h2>Stats</h2>
        <p className="lead">
        This tool create configuration files for awery ERP.
        Now we have <b>{st.tablesCount}</b> tables and <b>{st.fieldsCount}</b> fields,
         includes <b>{st.uniqFields}</b> unique field names.
        </p>
      </Plate>
    </div>
  );
};

const mapStateToProps = (state, owp ) => {
  const stat = state.dbs.stats;
  return {
    xhrStatus: state.dbs.xhrStatus,
    tablesCount: stat.tables,
    fieldsCount: stat.fields,
    uniqFields: stat.allFieldsNames && stat.allFieldsNames.length,
  }
}

const mergeProps  = (state, dispatchProps ) => ({
  ...state,
  ...dispatchProps,
  //loading: loader( state, dispatchProps, actions.loadStats() )
  loading: loader( state, dispatchProps, actions.loadAll() )
});

export default connect( mapStateToProps  ,null, mergeProps )(Welcome);;
