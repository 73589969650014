import * as ac from './action_const';
import update from 'immutability-helper';
import linkableTypes from './linkableTypes';
import * as dataSpec from './dataSpec';

export const initialState = {
    xhrStatus: {},
    tables: [],
    stats: {},
    td: {}
};

var reqFiieldsByType = {
    integer: {
        description: 'string',
        name: 'string',
        ref: 'ref'
    },

}


function getUpdateStats(tabledef) {
    const errors = [];
    const skipProp = {
        'pos': 1,
        'enum': 2,
        'indexed': 3,
        'default': 4,
        'descr': 5,


        'type': 1, 'size': 1
    }
    const skipFields = {
        'id': 1,
        'pid': 2,
        'type': 3,
        'descr': 4,
        'qty': 5,
        'code': 6,
        'notes': 7,
        'number': 8,
        'name': 9
    }
    var stats = {
        tables: 0,
        tablesAll: 0,
        fields: 0,
        fieldsAll: 0,
        enums: 0,
        enumsAll: 0,
        refFields: {},
        primaries: {},
        fieldsTables: {},
        relFrom: {},
        relTo: {},
        fieldsSpec: {},
        table: {},
        warn: {},
        warnByField: {},
        warnByTable: {},
        warnByPrio: {},
        typeTables: {},
        typeMap: {}
    };
    const warning = (table, field, prop, warn, prio) => {
        const pr = prio || 'warn';
        stats.warnByField[field] = (stats.warnByField[field] || 0) + 1;
        stats.warnByTable[table] = (stats.warnByTable[table] || 0) + 1;
        stats.warnByPrio[pr] = (stats.warnByPrio[pr] || 0) + 1
        stats.warn[table + '.' + field + '.' + prop] = {table, field, prop, warn, pr};
    }
    const typeCrawl = (typeTables, table, deep) => {
        const tt = typeTables[table];
        if (!tt) {
            console.log( 'ERR#77 Table ',table);
            return null;
        }
        deep = (deep || 0);
        if (deep > 10) {
            tt.loop = true;
            return tt;
        }
        if (tt.top) {
            tt.len = 0;
            tt.path = [];
            tt.hpath = tt.name;
            return tt; //Nothing todo for top-level table
        }
        const ret = typeCrawl(typeTables, tt.to_tbl, deep + 1);
        if( !ret ) return null;
        tt.name = ret.name;
        tt.hpath = ret.hpath + ' / ' + table;
        tt.path = [ {
            table: tt.table, from_fld: tt.from_fld,
            to_tbl: tt.to_tbl, to_fld: tt.to_fld
        }, ...(ret.path||[]) ]
        return tt;
    }

    var fspc = stats.fieldsSpec;
    const defFld = tabledef['_def_'].fld;


    for (var table in tabledef) {
        var td = tabledef[table];
        if (!td || !td.ver) {
            continue;
        }
        var sd = {
            d: td.description,
            inDb: td.ver,
            fl: 0, // Number of fields
            flc: 0, //
            it: 0, //Items described
            itc: 0,//Total items to describe
            refc: 0, //References
            en: 0, // Enums filed
            enc: 0
        }
        if (td.primary) {
            var pp = td.primary.split(',');
            for (var i = 0; i < pp.length; i++) {
                if( td.fld[pp[i]] ) {
                    const it = td.fld[pp[i]].type;
                    if (!stats.primaries[it]) stats.primaries[it] = [];
                    stats.primaries[it].push({value: table + '.' + pp[i], label: table + ' -> ' + pp[i]});
                }
            }
        }
        stats.tables += td.ver ? 1 : 0;
        stats.tablesAll++;
        for (var field in td.fld) {
            var fd = td.fld[field];
            if (!stats.fieldsTables[field]) stats.fieldsTables[field] = [];
            stats.fieldsTables[field].push(table)
            if (fd.ref) {
                const spl = fd.ref.split('.');
                const relTbl = spl[0];
                const relFld = spl[1];
                const relname = [ table, field, relTbl, relFld ].join('.')
                if (!stats.relFrom[relTbl]) stats.relFrom[relTbl] = [];
                stats.relFrom[relTbl].push(relname);

                if (!stats.relTo[table]) stats.relTo[table] = [];
                stats.relTo[table].push(relname);
            }
            if (false && linkableTypes[fd.type]) { //Disabled
                if (!stats.refFields[fd.type]) stats.refFields[fd.type] = [ '' ];
                stats.refFields[fd.type].push(table + '.' + field)
            }
            stats.fieldsAll++;
            stats.fields += (td.ver && fd.ver) ? 1 : 0;
            sd.flc++;
            sd.fl += (td.ver && fd.ver) ? 1 : 0;
            var tpd = reqFiieldsByType[fd.type];
            for (var ftd in tpd) {
                sd.itc++;
                sd.it += fd[ftd] ? 1 : 0
            }
            sd.refc += fd.ref ? 1 : 0;
            if (fd.type === 'enum' && fd.enum) {
                for (var enna in fd.enum) {
                    sd.enc++;
                    stats.enumsAll++;
                    sd.en += fd.enum[enna].description ? 1 : 0
                    stats.enums += fd.enum[enna].description ? 1 : 0
                }
            }
        }
        stats.allFieldsNames = Object.keys(stats.fieldsTables).sort();
        stats.table[table] = sd;
    }
    //Type groups
    //typeTables: {},
    //typeMap: {}
    for (const table in tabledef) {
        const td = tabledef[table];
        if (!td.parentref) continue; //No parent type
        switch (td.parentref) {
            case 'TOP':
                stats.typeTables[table] = {name: td.name || table, table, top: true};
                break
            default:
                const [ to_tbl, to_fld ] = td.fld[td.parentref].ref.split('.')
                stats.typeTables[table] = {
                    table, from_fld: td.parentref,
                    to_tbl, to_fld
                }
        }
    }
    for (const table in tabledef) {
        typeCrawl(stats.typeTables, table);
    }


    //Errors and warning check
    for (const table in tabledef) {
        const td = tabledef[table];
        for (const field in td.fld) {
            const fd = td.fld[field];
            const defFd = defFld[field] || {};
            for (var prop in fd) { //Props wrnings ####
                const tval = fd[prop];
                const defval = defFd[prop];
                const val = tval || defval || '';
                switch (prop) {
                    case 'ref': //Checking for broken references
                        const [ tbl, fld ] = val.split('.');
                        if ((!tabledef[tbl]) || (!tabledef[tbl].ver) ||
                            (!tabledef[tbl].fld[fld]) || (!tabledef[tbl].fld[fld].ver)) {
                            warning(table, field, prop, 'Broken ref', 'error');
                        }
                        break;
                    default:
                }
                if (skipFields[field] || skipProp[prop] || (!fd[prop]) || defFld[field].ignore_warn || fd.ignore_warn) continue;
                if (!fspc[field]) fspc[field] = {};
                if (fd[prop]) {
                    if (fspc[field][prop] && fspc[field][prop] !== fd[prop]) {
                        warning(table, field, prop, 'Mix props');
                    }
                    fspc[field][prop] = fd[prop];
                }
            }
        }
        //Checking table wide stuff
        //if( td.)
    }

    return stats;
}


const loadingReducer = (state = initialState, action) => {
    if (action.type.indexOf(ac.XHR_PREFIX_RAW) !== 0) { // not starts with prefix
        return state;
    }
    if (!action.expireIn || !action.uri) { // Not cahable
        return state;
    }
    if (action.type.endsWith(ac.XHR_SUFFIX_OK)) {
        // Succesifle request
        return {
            ...state,
            xhrStatus: {
                ...state.xhrStatus,
                [action.uri]: Date.now() + action.expireIn
            },
        };
    } else if (action.type.endsWith(ac.XHR_SUFFIX_ERR)) {
        // Failed request
        return {
            ...state,
            xhrStatus: {
                ...state.xhrStatus,
                [action.uri]: 0
            },
        };
    } else {
        //pending request
        return {
            ...state,
            xhrStatus: {
                ...state.xhrStatus,
                [action.uri]: -1
            },
        };
    }
}

const rootReducer = (state = initialState, action) => {
    state = loadingReducer(state, action);
    switch (action.type) {
        case ac.BE_UPDATE_STATS:
            return {...state, stats: getUpdateStats(state.td), tables: Object.keys(state.td).sort()}
        case ac.LOAD_ALL + ac.XHR_SUFFIX_OK:
            return {
                ...state,
                td: action.payload
            };
        case ac.LOAD_TABLES + ac.XHR_SUFFIX_OK:
            return {
                ...state,
                tables: action.payload.tables
            };
        case ac.LOAD_STATS + ac.XHR_SUFFIX_OK:
            return {
                ...state,
                stats: action.payload,
                tables: Object.keys(action.payload.table)
            };
        case ac.LOAD_TABLE + ac.XHR_SUFFIX_OK:
            return {
                ...state,
                td: {
                    ...state.td,
                    [action.payload.table]: action.payload.data
                }
            };
        case ac.REFRESH_DB + ac.XHR_SUFFIX_OK:
            return state; //Do nothing for now

        //changes
        case ac.UPDATE_FIELD_DEF:
            return update(state, {
                stats: {$set: getUpdateStats(state.td)},
                td: {
                    [action.table]: {
                        fld: {
                            [action.field]: {
                                $merge: dataSpec.filterProps(action.arg)
                            }
                        }
                    }
                }
            });
        case ac.UPDATE_TABLE_DEF:
            return update(state, {
                stats: {$set: getUpdateStats(state.td)},
                td: {
                    [action.table]: {
                        $merge: dataSpec.filterProps(action.arg)
                    }
                }
            });
        default:
            return state;
    }
}

export default rootReducer;
