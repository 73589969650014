import linkableTypesS from './linkableTypes';

export const linkableTypes = linkableTypesS;

//Fields disabled to edit in UI
export const reservedFields = {
    idx: "Index of table",
    ver: "data version",
    dynamic: "Dynamic row data size",
    autoIncrement: "Auto increment",
    primary: "Primary index",
    fld: "Fields definition",
    pos: "Field position",
    "default": "Default value",
    "null": "Null value allowed",
    "type": "Field type",
    size: "Field size",
    "enum": "Enum values"
}
export const filterProps = (obj) => {
    const ret = {};
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop) && !reservedFields[prop]) {
            ret[prop] = obj[prop];
        }
    }
    return ret;
}


const integerValidators = [
    {value: '', label: 'No validation'},
    {value: 'positive', label: 'Positive'},
    {value: 'range', label: 'Range', params: {from: 'From', to: 'To'}}
];
const stringValidators = [
    {value: '', label: 'No validation'},
    {value: 'notempty', label: 'Not empty'},
    {value: 'regexp', label: "Regexp", params: {regexp: 'parrern'}}
];
export const validators = {
    int: integerValidators,
    longint: integerValidators,
    mediumint: integerValidators,
    tinyint: integerValidators,
    char: stringValidators,
    varchar: stringValidators,
    text: stringValidators
}

export const captionTypes = {
    'char': true,
    'varchar': true
}

export const searcableOptions = [
    {value: "", label: "NO index"},
    {value: "100", label: "100 Most important"},
    {value: "75", label: "75 Important"},
    {value: "50", label: "50 ordinary"},
    {value: "25", label: "25 Not important"},
    {value: "5", label: "5 Just a hint"},
];
export const searchableTypes = {
    char: true,
    varchar: true,
    text: true,
    int: true

}