import React from "react";
import { connect } from "react-redux";
import * as actions from '../actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import arrayTool from '../arrayTool';
import FormFilter from '../ui/FieldFormFilter';
import Pager from '../ui/Pager';


const mapStateToProps = state => {
  return {
    page: state.ui.pagers.fields.page,
    perPage: state.ui.pagers.fields.perPage,
    defaults: (state.dbs.td && state.dbs.td._def_.fld ) || {},
    fields: state.dbs.stats.allFieldsNames,
    fieldsTables: state.dbs.stats.fieldsTables,
    fieldsFilter: ( state.form.filterForm && state.form.filterForm.values && state.form.filterForm.values.field_filter ) || '',
    tableFilter: ( state.form.filterForm && state.form.filterForm.values && state.form.filterForm.values.table_filter ) || ''
  }
};


const List = (st) => {
  const defaults = st.defaults;
  function content( obj ) {
    const arr = obj.data;
    const count = obj.total;
    if( !count ) {
      return ( <h1>Nothing found</h1> )
    }
    return (
      <Paper>
      <Pager name="fields" count={count}/>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Field
            </TableCell>
            <TableCell>
              tables
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
             {arr}
        </TableBody>
      </Table>
      </Paper>
    );
  }
  return (
    <div className="ds_block">
      <FormFilter />
      {
        content( arrayTool( st.fields , {
          from: st.page * st.perPage,
          to: (st.page+1) * st.perPage,
          asObject: true,
          filter: field =>(
            ( !st.tableFilter && !st.fieldsFilter) ||
             ( st.fieldsFilter && field.indexOf( st.fieldsFilter) >=0 ) ||
             ( st.tableFilter && st.fieldsTables[field] && st.fieldsTables[field].filter( tbl => tbl.indexOf( st.tableFilter) >=0 ).length )
           ),
           map: (field, idx) => {
               const defdef=defaults[field];
               const def={};
               return (
               <TableRow key={field} onClick={() => st.dispatch(  actions.goAllField( field))}>
                   <TableCell>
                     <i className="fld-name">{field}</i>
                     { (def.ref || defdef.ref )&& <i className={"fld-ref"  + (def.ref && ' orig')}>{def.ref || defdef.ref}</i>}
                     <div className="fld-extra">
                       <i className={"fld-human"}>{ defdef.human}</i>
                       <i className={"fld-descr"}>{ defdef.descr}</i>
                     </div>
                   </TableCell>
                   {
                     st.fieldsTables[field]
                     ? <TableCell numeric>{st.fieldsTables[field].length || ''}</TableCell>
                     : <TableCell numeric>Deleted</TableCell>
                   }
               </TableRow>
           )}
         }
       ))
    }
  </div>
  );
}
const ListFields = connect( mapStateToProps  ,null,null)(List);
export default ListFields;
