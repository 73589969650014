import {detailedDiff} from 'deep-object-diff';

const getChanges = ( init, changes ) => {
  const dd = detailedDiff(init,changes);
  return {
    ...dd.added,
    ...dd.deleted,
    ...dd.updated
  }
}

export default getChanges;
