//Constants for remote.js
export const XHR_PREFIX_RAW = 'Jsxhr';
export const XHR_SUFFIX_OK = '?ok';
export const XHR_SUFFIX_ERR = '?error';
export const XHR_SEPARATOR = '/';
export const XHR_PREFIX = XHR_PREFIX_RAW + XHR_SEPARATOR;
export const HTTP_ERROR = 'HTTP_ERROR';

export const CHANGE_PROP='DBS_CHANGE_PROP';
export const CHANGE_LINK='DBS_CHANGE_LINK';
export const TABLE_DEF_LOADED='DBS_TABLE_DEF_LOADED';
export const UI_CHANGE_TABLE='UI_CHANGE_TABLE';
export const UI_CHANGE_AC='UI_CHANGE_AC';
export const UPDATE_FIELD_DEF= XHR_PREFIX + 'UPDATE_FIELD_DEF';
export const UPDATE_TABLE_DEF= XHR_PREFIX + 'UPDATE_TABLE_DEF';

// UI
export const UI_TOGGLE = 'UI_TOGGLE';
export const UI_GO_HOME = 'UI_GO_HOME';
export const UI_GO_PAGE = 'UI_GO_PAGE';
export const UI_GO_FIELDS_SEARCH = 'UI_GO_FIELDS_SEARCH';
export const UI_GO_TABLE='UI_GO_TABLE';
export const UI_GO_FIELD='UI_GO_FIELD';
export const UI_GO_FIELDS_VIEW='UI_GO_FIELDS_VIEW';
export const UI_TOGGLE_MENU='UI_TOGGLE_MENU';
export const UI_SET_VIEW_MODE='UI_SET_VIEW_MODE';
export const UI_CHANGE_PAGE='UI_CHANGE_PAGE';
export const UI_CHANGE_PER_PAGE='UI_CHANGE_PER_PAGE';
export const UI_GO_ALL_FIELD='UI_GO_ALL_FIELD';
export const UI_GO_HISTORY='UI_GO_HISTORY';




// SERVER API
export const LOGIN = XHR_PREFIX + 'LOGIN';
export const LOGOUT = XHR_PREFIX + 'LOGOUT`';
export const VERIFY_SESSSION = XHR_PREFIX  +'VERIFY_SESSSION';
export const LOAD_TABLES = XHR_PREFIX  +'LOAD_TABLES';
export const LOAD_TABLE = XHR_PREFIX  +'LOAD_TABLE';
export const LOAD_ALL = XHR_PREFIX  +'LOAD_ALL';
export const LOAD_STATS = XHR_PREFIX  +'LOAD_STATS';
export const REFRESH_DB= XHR_PREFIX + 'REFRESH_DB';

//SERVER
export const BE_UPDATE_TABLE = 'BE_UPDATE_TABLE';
export const BE_UPDATE_STATS = 'BE_UPDATE_STATS';
export const BE_LOAD_DB = 'BE_LOAD_DB';
