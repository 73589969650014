import React from "react";
import {connect} from "react-redux";
import * as actions from '../actions';
import FieldDB from '../ui/Field';
//import Paper from '@material-ui/core/Paper';
import Paper from '../ui/Plate';
import getChanges from '../getChanges';
import {reduxForm, Field} from "redux-form";
import TextField from '@material-ui/core/TextField';
import VirSelectWrappedOrig from './FormCombobox';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import arrayTool from '../arrayTool';
import * as dataSpec from '../dataSpec';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const mapStateToProps = state => {
    return {
        tableId: state.ui.table,
        mode: state.ui.viewMode,
        table: state.dbs.td[state.ui.table] || {},
        relTo: state.dbs.stats.relTo[state.ui.table] || [],
        relFrom: state.dbs.stats.relFrom[state.ui.table] || [],
        typeTables: state.dbs.stats.typeTables,
        //refFields: state.dbs.stats.primaries || {},
        xhrStatus: state.dbs.xhrStatus
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        select: (table) => dispatch(actions.goTable(table)),
        setViewMode: (ev, mode) => {
            return dispatch(actions.setViewMode(mode))
        },
        selectField: (table, field) => dispatch(actions.goField(table, field))
    }
};


const validate = values => {
    return {}
}

const renderTextField = ({
                             input,
                             label,
                             meta: {touched, error},
                             ...custom
                         }) => (
    <TextField
        label={label}
        fullWidth
        error={touched && !!error}
        {...input}
        {...custom}
    />
)


class TableUC extends React.Component {
    render() {
        const st = this.props;
        const doSubmit = (val, dis, prop) => {
            const changed = getChanges(prop.initialValues, val);
            prop.dispatch(actions.updateTableDef(prop.table, changed));
        }
        const historymode = [
            {value: '', label: 'DEFAULT: History save OLD and NEW'},
            {value: '3', label: 'History save OLD and NEW'},
            {value: '2', label: 'History save NEW only'},
            {value: '0', label: 'Do not save history'},
        ];
        //const def = this.props.def;
        return (
            <form onSubmit={this.props.handleSubmit(doSubmit)}>
                <Field name="human" component={renderTextField} label="Human-readable name" type="text" placeholder=""/>
                <Field name="descr" component={renderTextField} label="Description" type="text" placeholder=""/>
                <Field name="comment" component={renderTextField} multiline label="Comment" type="text"
                       placeholder="Comment"/>
                {st.captionField &&
                <Field name="captionfield" component={VirSelectWrappedOrig} label="Caption field" type="text"
                       placeholder=""
                       options={st.captionField} fullWidth
                />}
                <Field name="caption" component={renderTextField} multiline label="Caption (for experts only)"
                       type="text" placeholder=""/>
                {st.parentRef &&
                <Field name="parentref" component={VirSelectWrappedOrig} label="Up level object reference" type="text"
                       placeholder=""
                       options={st.parentRef} fullWidth
                />}
                <Field name="historymode" component={VirSelectWrappedOrig} label="History mode" type="text"
                       placeholder=""
                       options={historymode} fullWidth
                />
                <Button type="submit" color="primary" variant="contained"
                        disabled={this.props.pristine || this.props.submitting}>Update</Button>
            </form>
        )
    }
}


class TableEdit extends React.Component {
    Form = null;
    Table = null;

    render() {
        if (this.Table !== this.props.table) {
            this.Table = this.props.table;
            this.Form = reduxForm({
                form: "table:" + this.props.table,
                destroyOnUnmount: true,
                validate
            })(TableUC)
        }
        return <this.Form {...this.props} />
    }
}

const List = (st) => {
    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs value={st.mode} onChange={st.setViewMode}>
                    <Tab label="Info"/>
                    <Tab label={"Fields " + Object.keys(st.table.fld).length}/>
                    <Tab label={"Related on " + st.relFrom.length}/>
                    <Tab label={"Rel to  " + st.relTo.length}/>
                </Tabs>
            </AppBar>
            {st.mode === 0 && // Info mode
            <Paper>
                {
                    st.table.fld &&
                    <TableEdit
                        table={st.tableId}
                        captionField={arrayTool(Object.keys(st.table.fld), {
                            filter: field => (dataSpec.captionTypes[st.table.fld[field].type]),
                            ordered: true,
                            prepend: [ {value: '', label: 'None'} ],
                            map: field => ({value: field, label: field})
                        })}
                        parentRef={arrayTool(Object.keys(st.table.fld), {
                            filter: field => (st.table.fld[field].ref &&
                                st.typeTables[st.table.fld[field].ref.split('.')[0]]),
                            ordered: true,
                            prepend: [ {value: 'TOP', label: '[ TOP level object ]'}, {
                                value: '',
                                label: '[ Independed ]'
                            } ],
                            map: field => ({
                                value: field,
                                label: // field +' -> ' +
                                //st.table.fld[field].ref +' - ' +
                                    st.typeTables[st.table.fld[field].ref.split('.')[0]].hpath +
                                    ' <- ' + field
                            })
                        })}
                        initialValues={st.table}
                    />
                }
            </Paper>
            }
            {st.mode === 1 && // Fields mode
            <div className="ds_block">
                {
                    st.table.fld && Object.keys(st.table.fld).sort((a, b) => {
                        const ad = st.table.fld[a], bd = st.table.fld[b];
                        return ad.pos > bd.pos ? 1 : -1;
                    }).map(field => (
                        <FieldDB key={field + st.tableId} field={field} table={st.tableId} def={st.table.fld[field]}
                                 goField={() => {
                                     st.dispatch(actions.goAllField(field))
                                 }}
                                 click={st.selectField}
                        />
                    ))
                }
            </div>
            }
            {st.mode === 3 && // Related on mode
            <div className="ds_block">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>This</TableCell>
                            <TableCell>Table</TableCell>
                            <TableCell>Field</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            st.relTo.map(r => {
                                const p = r.split('.');
                                return <TableRow key={r} onClick={() => st.dispatch(actions.goTable(p[2]))}>
                                    <TableCell>{p[0]}.{p[1]}</TableCell>
                                    <TableCell>{p[2]}</TableCell>
                                    <TableCell>{p[3]}</TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
            }
            {st.mode === 2 &&
            <div className="ds_block">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Table</TableCell>
                            <TableCell>Field</TableCell>
                            <TableCell>This</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            st.relFrom.map(r => {
                                const p = r.split('.');
                                return <TableRow key={r} onClick={() => st.dispatch(actions.goTable(p[0]))}>
                                    <TableCell>{p[0]}</TableCell>
                                    <TableCell>{p[1]}</TableCell>
                                    <TableCell>{p[2]}.{p[3]}</TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </div>
            }

        </div>
    );
}
const ListEntities = connect(mapStateToProps, mapDispatchToProps, null /*mergeProps*/)(List);
export default ListEntities;
