import React from 'react';
import { connect } from "react-redux";
import * as actions from '../actions';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';
import UpIcon from '@material-ui/icons/ArrowUpward';
import HomeIcon from '@material-ui/icons/Home';
import arrayNav from '../arrayNav';
import Button from '@material-ui/core/Button';

const NavBarButtons = ( st ) => {
  switch( st.page ) {
    case 'index':
      return (<div>
        <Button color="inherit" onClick={()=>{ st.dispatch( actions.goFieldsSearch())}}  >Fields</Button>
        <IconButton
           color="inherit"
            onClick={()=>{st.dispatch( actions.goPage( 'home'))}}>
          <HomeIcon />
        </IconButton>
        </div>)
    case 'table':
      return ( st.tables ? (<div>
          <IconButton
             color="inherit"
              onClick={()=>{st.dispatch( actions.goTable( arrayNav(st.tables, st.table, -1 )))}}>
            <PrevIcon />
          </IconButton>
          <IconButton
              color="inherit"
              onClick={()=>{st.dispatch( actions.goHome())}}>
            <UpIcon />
          </IconButton>
          <IconButton
              color="inherit"
              onClick={()=>{st.dispatch( actions.goTable( arrayNav(st.tables, st.table, 1 )))}}>
            <NextIcon />
          </IconButton>
          </div>) : <div/>);
    case 'fields_search':
      return (<div>
        <Button color="inherit" onClick={()=>{ st.dispatch( actions.goHome())}}  >tables</Button>
        <IconButton
           color="inherit"
            onClick={()=>{st.dispatch( actions.goPage( 'home'))}}>
          <HomeIcon />
        </IconButton>
        </div>)
    case 'allfield':
      return (st.fields ? (<div>
          <IconButton
             color="inherit"
              onClick={()=>{st.dispatch( actions.goAllField( arrayNav(st.fields, st.field, -1 )))}}>
            <PrevIcon />
          </IconButton>
          <IconButton
              color="inherit"
              onClick={()=>{st.dispatch( actions.goFieldsSearch())}}>
            <UpIcon />
          </IconButton>
          <IconButton
              color="inherit"
              onClick={()=>{st.dispatch( actions.goAllField( arrayNav(st.fields, st.field, 1 )))}}>
            <NextIcon />
          </IconButton>
          </div>) : <div/>);
    default:
      return (<div/>);
  }
}

const mapStateToProps = state => {
  return {
    page: state.ui.page,
    table: state.ui.table,
    field: state.ui.field,
    tables: state.dbs.tables,
    fields: state.dbs.stats && state.dbs.stats.allFieldsNames
  }
};


const mergeProps  = (state, dispatchProps ) => ({
  ...state,
  ...dispatchProps,
});

export default connect( mapStateToProps  ,null,mergeProps)(NavBarButtons);
