import * as ac from './action_const';

export default function loader( state, dispatchProps, action) {
  // check action correct
  if( action.type.indexOf( ac.XHR_PREFIX_RAW ) !== 0 || !action.expireIn || !action.uri ) { // Not cahable
    throw Error( "Wrong action")
  }
  // if loading return true
  if( state.xhrStatus[ action.uri ] === -1 ) {
    return true;
  }
  // if !expired return false
  const cached = state.xhrStatus[ action.uri ];
  if(  cached &&  cached > Date.now() ) {
    return false;
  }
  // dispatch an action
  dispatchProps.dispatch( action );
  return true;
}
