import React from 'react'
import Paper from '@material-ui/core/Paper';

export default (st) => (
  <div className="outer-plate">
    <Paper>
      <div className="inner-plate">
        {st.children}
      </div>
    </Paper>
  </div>
)
