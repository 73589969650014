import React from 'react';
import {reduxForm, Field} from "redux-form";
//import * as actions from "../actions";
import TextField from '@material-ui/core/TextField';

const doSubmit = (values, dispatch) => {
  //const ret = dispatch( login( values.login, values.password ) )
  console.log( 'Filter',values)
};

const validate = values => { return {}}

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    error={touched && !!error}
    {...input}
    {...custom}
  />
)

const form = ({ handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit( doSubmit )}>
    <Field name="table_filter" component={renderTextField} label="Table filter" type="text" placeholder="Table filter" />
    &nbsp;<span>&nbsp;</span>&nbsp;
    <Field name="field_filter" component={renderTextField} label="Field filter" type="text" placeholder="Field filter" />
  </form>
)

const FormFilter = reduxForm({
  form:'filterForm',
  destroyOnUnmount: false,
  validate
})(form)

export default  FormFilter;
