import React, { Component } from 'react';
import { connect } from "react-redux";
import '../App.css';
import Welcome from '../ui/Welcome';
import FormLogin from '../ui/FormLogin';
import EditTable from '../ui/EditTable'
import ListEntities from '../ui/listEntities';
import MainNavBar from '../ui/MainNavBar';
import ListFields from '../ui/ListFields';
import * as act from "../actions";
import ListAllFields from '../ui/ListAllFields';


class App extends Component {
  componentWillMount() {
    this.props.startup();
  }
  render() {
    const page = this.props.page;
    return (
      <div>
      {
        this.props.loggedIn ? (
          <div>
          <MainNavBar/>
          {
            ( page === 'index') ? ( <ListEntities/> ) :
            ( page === 'table') ? ( <EditTable/> ) :
            ( page === 'fields_search') ? ( <ListFields/> ) :
            ( page === 'allfield') ? ( <ListAllFields field={this.props.field}/> ) :
            ( <Welcome/> )
          }
          </div>
        ) : (
          <FormLogin/>
        )
      }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.acl.loggedIn,
    page: state.ui.page,
    table: state.ui.table,
    field: state.ui.field
  };
};


const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch( act.verifySession())
});

const AppConnected = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppConnected;
