import React from "react"
import PropTypes from "prop-types";
import {connect } from "react-redux";
import * as dataSpec from "../dataSpec";
import arrayTool from "../arrayTool";

import TextField from "@material-ui/core/TextField";
import {reduxForm, Field} from "redux-form";
import Button from "@material-ui/core/Button";
import getChanges from "../getChanges";
import * as actions from "../actions";
import VirSelectWrappedOrig from "./FormCombobox";
import InputAdornment from "@material-ui/core/InputAdornment";


const validate = values => {
    return {}
}

const renderTextField = ({
                             input,
                             label,
                             meta: {touched, error},
                             ...custom
                         }) => (
    <TextField
        fullWidth
        label={label}
        error={touched && !!error}
        {...input}
        {...custom}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                Default
            </InputAdornment>
        }}
    />
)

class FieldUC extends React.Component {
    render() {
        const st = this.props;
        const doSubmit = (val, dis, prop) => {
            const changed = getChanges(prop.initialValues, val);
            prop.dispatch(actions.updateFieldDef(prop.table, prop.field, changed))
        }
        const def = st.def;
        const suggestions = arrayTool(st.refFields, {
            ordered: true,
            prepend: [ {value: "-", label: "None"}, st.defaults.ref && {value: "", label: "Default: " + st.defaults.ref} ]
        })//.map(v =>({value:v,label:v}));
        return (
            <form onSubmit={this.props.handleSubmit(doSubmit)}>
                {
                    suggestions.length &&
                    <Field name="ref" component={VirSelectWrappedOrig} label="Reference to" type="text"
                           placeholder="no reference"
                           options={suggestions} fullWidth
                    />
                }
                <Field name="human" component={renderTextField} label="Human-readable name" type="text"
                       helperText={st.defaults.human && ("Default: " + st.defaults.human)} placeholder="table filter"/>
                <Field name="descr" component={renderTextField} label="Description" type="text"
                       helperText={st.defaults.descr && ("Default: " + st.defaults.descr)} placeholder="field filter"/>
                {
                    def.type === "enum" && def.enum && Object.keys(def.enum).map(enk => (
                        <Field key={enk} name={"enum_" + enk} component={renderTextField} label={"Enum " + enk + ""}
                               type="text" placeholder={enk}/>
                    ))
                }
                {
                    dataSpec.searchableTypes[def.type] && <Field name="search" component={VirSelectWrappedOrig}
                                                                 label="Search index priority" type="text"
                                                                 placeholder=""
                                                                 options={dataSpec.searcableOptions} fullWidth
                    />
                }
                {
                    dataSpec.validators[def.type] &&
                    <Field name="validate" component={VirSelectWrappedOrig} label="Validator" type="text" placeholder=""
                           options={dataSpec.validators[def.type]} fullWidth
                    />
                }

                <Field name="ignore_warn" component={VirSelectWrappedOrig} label="warning mode" type="text"
                       placeholder=""
                       options={[ {value: "", label: "Show warning"}, {value: "1", label: "Ignore warnings"} ]} fullWidth
                />
                <Button className="marg10" type="submit" color="primary" variant="contained"
                        disabled={this.props.pristine || this.props.submitting}>Update</Button>
                {st.goTable && <Button onClick={st.goTable} className="marg10" variant="contained">Table view</Button>}
                {st.goField && <Button onClick={st.goField} className="marg10" variant="contained">Field view</Button>}
            </form>
        )
    }
}


class FieldEdit extends React.Component {
    EditFieldForm = null;

    render() {
        const st = this.props;
        if (!st.fd) return (
            <div></div>
        );
        if (!this.EditFieldForm) this.EditFieldForm = reduxForm({
            form: st.fkey,
            validate
        })(FieldUC);
        return (
            <this.EditFieldForm
                id={st.key}
                table={st.table}
                field={st.field}
                initialValues={st.fd}
                refFields={st.refs}
                def={st.fd}
                defaults={st.fddef}
                goTable={st.goTable}
                goField={st.goField}
            />
        )
    }
}

FieldEdit.propTypes = {
    table: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    goField: PropTypes.func,
    goTable: PropTypes.func
};

const mapStateToProps = (state, owp) => {
    const table = owp.table;
    const field = owp.field;
    const key = table + ":" + field;
    const fd = state.dbs.td[table] && state.dbs.td[table].fld[field];
    const fddef = (state.dbs.td["_def_"] && state.dbs.td["_def_"].fld[field]) || {};
    return {
        //...owp,
        fd: fd || null,
        fddef,
        fkey: key,
        refs: fd && fd.type && state.dbs.stats.primaries && state.dbs.stats.primaries[fd.type]
    }
}


export default connect(mapStateToProps, null, null)(FieldEdit);
;
