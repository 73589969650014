function objectContains( arr, filter ) {
  let ret = false;
  if( !arr ) return ret;
  if( !filter ) return !!arr;
  for( let key in arr ) {
    if( filter( key, arr[key]))
      ret = true;
  }
  return ret;
}
export default objectContains;
