import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconKey from '@material-ui/icons/VpnKey';
import IconNull from '@material-ui/icons/Clear';
import IconIndex from '@material-ui/icons/VpnKey';
import IconRef from '@material-ui/icons/Link';
import * as actions from '../actions';

import FieldEdit from '../ui/FieldEdit';


export class DbField extends React.Component {
  state = {
    onceOpened: false,
  };
  constructor(props) {
     super(props);
     this.EditFieldForm = null;
  };
  onExpand(a,b) {
    if( !this.EditFieldForm ) {
      this.EditFieldForm = FieldEdit
    }
    this.setState({onceOpened: true});
  }
  click() {
    this.props.click( this.props.table, this.props.id )
  }
  render() {
    const def = this.props.def || {};
    const defdef = this.props.defdef || {}; //Defaults definition
      return (
        <ExpansionPanel onChange={this.onExpand.bind(this)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className="fld-narated">
              <i className="fld-name">{this.props.table} . {this.props.field}</i>
              {
                (!def.ver) && <i className="fld-deleted">Deleted</i> 
              }
              <i className="fld-type">{def.type || defdef.type }({def.size || defdef.size})</i>
              { (def.ref || defdef.ref )&& <i className={"fld-ref"  + (def.ref && ' orig')}>{def.ref || defdef.ref}</i>}
              <i className="fld-icons">
                { def.primary && (<IconKey className="ico-primary-key"/>) }
                {  def.null && <IconNull className="ico-null"/> }
                {  def.indexed && <IconIndex className="ico-indexed"/>  }
                {  def.ref && <IconRef className="ico-reference"/> }
              </i>
            <div className="fld-extra">
              <i className={"fld-human" + (def.human && ' orig')}>{def.human || defdef.human}</i>
              <i className={"fld-descr"  + (def.descr && ' orig')}>{def.descr || defdef.descr}</i>
            </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {
              this.state.onceOpened &&
            <this.EditFieldForm
              field={this.props.field}
              table={this.props.table}
              goField={this.props.goField}
              goTable={this.props.goTable}
            />
           }
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
   }
}

const mapStateToProps = (state, owp ) => {
  const field = owp.field;
  const table = owp.table;
  const ret ={
    def: (state.dbs.td[table] && state.dbs.td[table].fld[field] ) || {},
    defdef: (state.dbs.td['_def_'] && state.dbs.td['_def_'].fld[field] ) || {},
  }
  return ret;
}
const mapDispatchToProps = ( dispatch, owp ) => ({
    onExpand: () => {
      dispatch( actions.uiToggle( ''.owp.table+'.'.owp.field))
    }
})


DbField.propTypes = {
   table: PropTypes.string.isRequired,
   field: PropTypes.string.isRequired,
   goField: PropTypes.func,
   goTable: PropTypes.func,
   click: PropTypes.func
};

export default connect( mapStateToProps  ,null ,null)(DbField);
