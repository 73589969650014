import {
  combineReducers,
  createStore,
  applyMiddleware
} from "redux";
import * as ac from './action_const';
import * as actions from './actions';
import {
  reducer as reduxFormReducer
} from 'redux-form';
import * as remote from './remote';
import dbs from "./dbsStore";
import ui from "./uiStore";
import acl from "./aclStore";

import {
  composeWithDevTools
} from 'redux-devtools-extension';



const combined = combineReducers({
  form: reduxFormReducer,
  dbs,
  ui,
  acl
});

//Stats updater
export const middleware = store => next => action => {
  switch (action.type) {
    case ac.LOAD_ALL + ac.XHR_SUFFIX_OK:
    case ac.LOAD_TABLE + ac.XHR_SUFFIX_OK:
    case ac.REFRESH_DB + ac.XHR_SUFFIX_OK:
      setTimeout(() => {
        store.dispatch( actions.udateStats() )
      },10);
      break;
    default: //nothing
  }
  return next(action);
}

const store = createStore(combined, composeWithDevTools(
  applyMiddleware(remote.middleware, middleware), //, logger
  // other store enhancers if any
));
window._store_ = store;
export default store;
