import React from "react";
import { connect } from "react-redux";
import * as actions from '../actions';
import TablePagination from '@material-ui/core/TablePagination';

const mapStateToProps = state => {
  return {
    pagers: state.ui.pagers,
  }
};

const Pager = (st, pr) => {
  const pgr = st.pagers[st.name] || { page:0, perPage: 25} ;
  return (
    <TablePagination
        component="div"
        count={st.count}
        rowsPerPage={pgr.perPage}
        page={pgr.page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={(event,page) => st.dispatch(actions.uiChangePage(st.name, page))}
        onChangeRowsPerPage={(event) => st.dispatch(actions.uiChangePerPage(st.name, event.target.value))}
      />
  )
}
const PagerConnected = connect( mapStateToProps  ,null,null)(Pager);

export default PagerConnected;
