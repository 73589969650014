
import * as ac from "./action_const";


const initialState = {
  loggedIn: false,
  user: ''
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ac.HTTP_ERROR+ '_401':
    return {...state,loggedIn:false, user: ''};
    case ac.VERIFY_SESSSION + ac.XHR_SUFFIX_OK:
      return {...state,loggedIn: !!action.payload.sid, user: action.payload.login, sid: action.payload.sid};
    case ac.LOGIN + ac.XHR_SUFFIX_OK:
      return {...state,loggedIn: !!action.payload.sid, user: action.payload.login, sid: action.payload.sid};
    case ac.LOGOUT:
      return {...state,loggedIn:false, user: ''};
    default:
      return state;
  }
};
export default rootReducer;
