import React from "react";
import { connect } from "react-redux";
import Pager from '../ui/Pager';
import Paper from '@material-ui/core/Paper';
import FormFilter from '../ui/FieldFormFilter';
import arrayTool from '../arrayTool';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as actions from '../actions';
import FieldEdit from '../ui/FieldEdit';
//import Table from '../ui/Table';
import Field from '../ui/Field';



const mapStateToProps = (state, owp ) => {
  const field = owp.field;
  const tables =state.dbs.stats.fieldsTables[field] || [];
  const ret ={
      tables: tables,
      fieldId: field,
      mode: state.ui.viewMode,
  }
  return ret;
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        select: ( table ) => dispatch( actions.goTable( table)),
        setViewMode: ( ev, mode ) => {
          return dispatch( actions.setViewMode( mode ))
        },
        selectField: ( table, field ) => dispatch( actions.goField( table, field))
    }
};

const List = (st) => {
  function content( obj ) {
    return (
      <Paper>
        <Pager name="fieldslist" count={obj.total}/>
        {obj.data}
      </Paper>
    )
  }
  return (
    <div className="azcont">
      <div className="azleft">
      </div>
      <div className="azmain">
        <AppBar position="static" color="default">
          <Tabs value={st.mode} onChange={st.setViewMode}>
            <Tab label="Defaults" />
            <Tab label={"Tables " + st.tables.length} />
            <Tab label={"Info"} />
            <Tab label={"History"} />
          </Tabs>
        </AppBar>
        {st.mode === 0 && // Info mode
          <Paper>
           <FieldEdit table="_def_" field={st.fieldId} />
          </Paper>
        }
        {st.mode === 1 && // Info mode
          <Paper>
          <FormFilter hide_fields />
          {
             content( arrayTool( st.tables , {
              from: st.page * st.perPage,
              to: (st.page+1) * st.perPage,
              asObject: true,
              filter: table =>(
                 ( !st.tableFilter ) ||
                 ( st.tableFilter && table.indexOf( st.tableFilter) >=0 )
               ),
               map: (table, idx) => (
                 <Field key={table+st.fieldId} table={table} field={st.fieldId}
                  goTable={()=>{st.dispatch(actions.goTable(table))}}
                 />
               )
             }
           ))
          }
          </Paper>
        }
        </div>
    </div>)
}

const ListAllFields = connect( mapStateToProps  ,mapDispatchToProps,null)(List);
export default ListAllFields;
