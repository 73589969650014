import React from 'react';
import {reduxForm, Field} from "redux-form";
import {login} from "../actions";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const doSubmit = (values, dispatch) => {
  const ret = dispatch( login( values.login, values.password ) )
  ret.then( () => {
    console.log('Done');
  })
  console.log( 'ret',ret)
  return ret;
};

const validate = values => {
  const errors = {}
  if (!values.login || values.login.length < 2) {
    errors.login = 'Required'
  }
  if (!values.password || values.password < 1) {
    errors.password = 'Required'
  }
  return errors
}


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  menu: {
    width: 200,
  },
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
  }),
});


const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    error={touched && !!error}
    {...input}
    {...custom}
  />
)

const form = ({ handleSubmit, submitting, classes, pristine }) => (
  <div className="login-form">
    <Paper className={classes.root} elevation={4}>
      <form onSubmit={handleSubmit( doSubmit )}>
        <h1>Database structure editor</h1>
        <Field name="login" component={renderTextField} label="User name" type="text" placeholder="Username" className={classes.textField} />
        <Field name="password" component={renderTextField} label="Password" type="password" placeholder="Password" className={classes.textField} />
        <Button type="submit" color="primary" variant="contained" disabled={pristine || submitting} >Log in</Button>
      </form>
    </Paper>
  </div>
)

const FormLogin = reduxForm({
  form:'loginForm',
  validate
})(withStyles(styles)(form))

export default  FormLogin;
