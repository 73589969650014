import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './ui/App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from "react-redux";
import store from "./store";

//import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'

ReactDOM.render(<Provider store={store}>
  <App />
</Provider>
, document.getElementById('root'));
unregisterServiceWorker();
