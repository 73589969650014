const config = {
  server: {
      //url:"http://rolubuntu:3333/api/",
      //url: "http://hz3.awery.aero:3333/api",
      url:"/api/",
      apiUri: "api"
  }
}

export function get( key ) {
  const path = ( key || '').split('.');
  let p = config;
  for( var i = 0; i < path.length; i++  ) {
    p = p[path[i]]; //Travel on path
    if( !p ) return; // Path not found
  }
  return p;
}
