import React from "react";
import { connect } from "react-redux";
import loader  from '../loader';
import * as actions from '../actions';
import FormFilter from '../ui/FieldFormFilter';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import objectContains from '../objectContains';
import Pager from '../ui/Pager';
import Paper from '@material-ui/core/Paper';
import arrayTool from '../arrayTool';



const mapStateToProps = state => {
  return {
    tables: state.dbs.tables,
    tableStat: state.dbs.stats.table,
    typeTables: state.dbs.stats.typeTables,
    td: state.dbs.td,
    xhrStatus: state.dbs.xhrStatus,
    page: state.ui.pagers.tables.page,
    perPage: state.ui.pagers.tables.perPage,
    fieldsFilter: ( state.form.filterForm && state.form.filterForm.values && state.form.filterForm.values.field_filter ) || '',
    tableFilter: ( state.form.filterForm && state.form.filterForm.values && state.form.filterForm.values.table_filter ) || ''
  }
};


const mergeProps  = (state, dispatchProps ) => ({
  ...state,
  ...dispatchProps,
  //loading: loader( state, dispatchProps, actions.loadStats() )
  loading: loader( state, dispatchProps, actions.loadAll() )
});


const List = (st) => {
  function content( obj ) {
    const arr = obj.data;
    const count = obj.total;
    if( !count ) {
      return ( <h1>Nothing found</h1> )
    }
    return (
      <Paper>
        <Pager name="tables" count={count}/>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Table
              </TableCell>
              <TableCell>
                Fields
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
               {arr}
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return (
    <div className="ds_block">
      <FormFilter />
      {
         content( arrayTool( st.tables , {
          from: st.page * st.perPage,
          to: (st.page+1) * st.perPage,
          asObject: true,
          filter: table =>( table !== '_def_' && st.td[table].ver && (
             ( !st.tableFilter && !st.fieldsFilter) ||
             ( st.tableFilter && table.indexOf( st.tableFilter) >=0 ) ||
             ( st.fieldsFilter && st.td[table] && objectContains( st.td[table].fld,  ( key, obj ) => (
               key.indexOf( st.fieldsFilter) >=0
             ))))
           ),
           map: (table, idx) => (
             <TableRow key={table} onClick={() => st.dispatch(  actions.goTable( table))}>
                 <TableCell>
                   <b>{table}</b>
                   {st.typeTables[table] && <i className={st.typeTables[table] ? (st.typeTables[table].top ? 'tttop' : 'tthave'):''} >
                   &nbsp;{st.typeTables[table].hpath}</i>}
                   {st.td[table].human || ''}
                   <br/>{st.td[table].descr || ''}
                 </TableCell>
                 {
                   st.tableStat[table]
                   ? <TableCell numeric><div
                    className={st.typeTables[table] ? (st.typeTables[table].top ? 'tttop' : 'tthave'):''} >
                    {st.tableStat[table].fl || ''}</div></TableCell>
                   : <TableCell numeric>Deleted</TableCell>
                 }
             </TableRow>
           )
         }
       ))
    }
  </div>
  );
}

const ListEntities = connect( mapStateToProps  ,null,mergeProps)(List);
export default ListEntities;
