import React from 'react';
import { connect } from "react-redux";
import * as actions from '../actions';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';
import Menu from '@material-ui/core/Menu';
import  MenuItem  from '@material-ui/core/MenuItem';
import NavBarButtons from '../ui/NavBarButtons';

const mapStateToProps = state => {
  return {
    isOpen: state.ui.menuOpen,
    title: state.ui.title
  }
};


const mergeProps  = (state, dispatchProps ) => ({
  ...state,
  ...dispatchProps,
});

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};


class MainNavBarPure extends React.Component {
  state = {
    anchorEl: null,
  };
  handleClose = () => {
   this.setState({ anchorEl: null });
  };
  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleHome = () => {
   this.setState({ anchorEl: null });
   this.props.dispatch(  actions.goHome());
  };
  handleFieldsSearch = () => {
    this.setState({ anchorEl: null });
    this.props.dispatch(  actions.goFieldsSearch());
   };
  handleLog = () => {
    this.props.dispatch(  actions.goHistory());
  }
  render() {
    const st = this.props;
    const stt = this.state;
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={st.classes.menuButton} color="inherit" aria-label="Menu" aria-haspopup="true"
              onClick={this.handleOpen}>
            <MenuIcon />
          </IconButton>

           <Menu
             id="simple-menu"
             anchorEl={stt.anchorEl}
             open={!!stt.anchorEl}
             onClose={this.handleClose}
           >
             <MenuItem onClick={this.handleHome}>Tables</MenuItem>
             <MenuItem onClick={this.handleFieldsSearch}>Fields</MenuItem>
             <MenuItem onClick={this.handleLog}>Events</MenuItem>
           </Menu>
          <Typography variant="h6" color="inherit" className={st.classes.flex}>
            {st.title|| 'dbZnay'}
          </Typography>
          <NavBarButtons/>
        </Toolbar>
      </AppBar>
    )
  }
}

const MainNavBar = connect( mapStateToProps  ,null,mergeProps)(withStyles(styles)(MainNavBarPure));


export default MainNavBar;
