import React from 'react'
import Select from "react-select";



function VirSelectWrappedOrig(props) {
  const { children, input, options } = props
  function handleInputChange({ value }) {
    props.input.onChange(value)
  }
  const onBlur2 = () => {
    input.onBlur(input.value)
  }
  return (
    <label className={props.className}>
      {children}
      {props.label && <div className="slabel" >{props.label}</div>}
      <Select
        clearable={false}
        searchable={true}
        options={options}
        defaultValue={input.value}
        name={input.name}
        value={options.find( a=> a.value === input.value)}
        onChange={handleInputChange}
        onBlur={onBlur2}
        onBlurResetsInput={false}

      />
    </label>
  );
}

export default VirSelectWrappedOrig;
