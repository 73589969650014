
import * as cfg from './config';
import * as ac from './action_const';


let vars = {
  session: window.localStorage.sid,
  sessionHeader: 'sid',
  baseUrl: cfg.get('server.url')
};

const defaultXHRHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};


export const middleware = store => next => action => {
  if( typeof action.type !== 'string' || action.xhrt ) {
    return next( action );
  }
  let p = action.type.split(ac.XHR_SEPARATOR);
  if( p[0] !== ac.XHR_PREFIX_RAW) {
    return next( action );
  }
  //Only XHR requests
  let method = action.method || ( action.post ? 'POST' : 'GET' );
  let uri = action.uri || 'info';
  let options = {
    mode: 'same-origin', //cors
    method: method
  };
  switch (method) {
    case 'POST':
    case 'PUT':
      options.body = JSON.stringify( action.arg );
      break;
    case 'event':
      options.body = JSON.stringify( action );
      options.method = 'POST';
      break;
    default:

  }
  next( action );
  return fetch(vars.baseUrl+uri, {...options,headers:{
      ...defaultXHRHeaders,
      [vars.sessionHeader]:vars.session
    }})
    .then( response => {
      if (response.status === 200 || response.status === 304) {
        return response.json().then( json =>{
            if( action.type === ac.LOGIN && json.sid ) {
              vars.session = window.localStorage.sid = json.sid;
            }
            return store.dispatch({
              ...action,
              xhrt: Date.now(),
              type: action.type + ac.XHR_SUFFIX_OK,
              payload: json
            })
        })
        .catch( err => {
            return store.dispatch({
              ...action,
              xhrt: Date.now(),
              type: action.type + ac.XHR_SUFFIX_ERR,
              error: err
            })
          })
      } else {
        store.dispatch({
          ...action,
          type: ac.HTTP_ERROR+'_'+response.status,
          xhrt: Date.now(),
          oldType: action.type,
        })
        return store.dispatch({
          ...action,
          xhrt: Date.now(),
          type: action.type + ac.XHR_SUFFIX_ERR,
          errorCode: response.status
        })
      }
    })
    .catch( err => {
        return store.dispatch({
          ...action,
          xhrt: Date.now(),
          type: action.type + ac.XHR_SUFFIX_ERR,
          error: err
        })
      }
    )
}
